class SectionExpertise {

  constructor () {
    this.container_selector = '.flex-expertise'
    this.$container = $(this.container_selector)

    if (this.$container.length) {
      this.menu_selector = '.flex-expertise-menu'
      this.$menu = $(this.menu_selector)
      this.$single = $('.flex-expertise-single')
      this.$form = $('.flex-expertise-form')
      this.sticky_selector = 'sticky'
      this.width_category = GlobalController.getWindowWidthCategory()

      this.initGsap()
      this.$single.on('click', '.flex-expertise-cta a', this.handleClickSectionCta.bind(this))
      this.$menu.on('click', 'a', this.handleClickMenu.bind(this))
      this.$menu.on('click', '.flex-expertise-menu-mobile-title', this.handleClickMenuMobile.bind(this))
    }
  }

  handleClickSectionCta (e) {
    e.preventDefault()
    let section_id = $(e.target).closest('.flex-expertise-single').data('id')

    let scroll = $('.flex-expertise-form').offset().top - $('.header').height()
    $('html, body').stop().animate({
      scrollTop: scroll
    }, 300)
    this.$form.find('select').val(section_id).trigger('change')
  }

  handleClickMenuMobile (e) {
    e.preventDefault()
    $(e.target).parent().toggleClass('open')
  }

  handleClickMenu (e) {
    let $button = $(e.target)
    let $section = $($button.attr('href'))

    if ($section.length) {
      let scroll = $section.offset().top - $('.header').height()

      this.$menu.removeClass('open')

      if ($button.hasClass('reset')) {
        this.$form.find('select').val('').trigger('change')
      }

      $('html, body').stop().animate({
        scrollTop: scroll
      }, 300)
    }
  }

  initGsap () {
    /* GSAP */
    let self = this

    gsap.registerPlugin(ScrollTrigger)

    $(window).on('resize', () => ScrollTrigger.refresh())

    gsap.utils.toArray(this.container_selector).forEach(layer => {
      let $menu = $(layer).find(self.menu_selector)
      ScrollTrigger.create({
        trigger: layer,
        pin: self.menu_selector,
        start: () => {
          if (self.width_category === 'lg' || self.width_category === 'md' || self.width_category === 'sm' || self.width_category === 'xs') {
            return `top bottom-=${$menu.outerHeight(true)}px`
          } else {
            return `top top+=${$('.header').outerHeight(true)}px`
          }
        },
        end: () => {
          if (self.width_category === 'lg' || self.width_category === 'md' || self.width_category === 'sm' || self.width_category === 'xs') {
            return `bottom bottom`
          } else {
            return `bottom top+=${$menu.outerHeight(true) + $('.header').outerHeight(true) + 30}px`
          }
        },
        invalidateOnRefresh: true,
        pinSpacing: false,
        onEnter: () => $menu.addClass(self.sticky_selector),
        onLeave: () => $menu.removeClass(self.sticky_selector),
        onEnterBack: () => $menu.addClass(self.sticky_selector),
        onLeaveBack: () => $menu.removeClass(self.sticky_selector),
      })
      $(layer).find('.flex-expertise-single').each((i, single) => {
        let $single = $(single)
        ScrollTrigger.create({
          trigger: '#'+$single.attr('id'),
          start: 'top top+=100px',
          end: 'bottom bottom-=100px',
          // markers: true,
          invalidateOnRefresh: true,
          onEnter: () => {
            $menu.find('.active').removeClass('active')
            $menu.find(`[data-id="${$single.data('id')}"`).addClass('active')
          },
          onEnterBack: () => {
            $menu.find('.active').removeClass('active')
            $menu.find(`[data-id="${$single.data('id')}"`).addClass('active')
          },
        })
      })
    })
  }

}

new SectionExpertise()