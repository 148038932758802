let $ = jQuery;
(function ($) {
  $(document).ready(function () {

    new GlobalController()
    new SelectorController()
    new HeaderController()
    new HeroController()
    new BlogController()
    // new TrackingController()

    // Remove alert on acf_form
    if (typeof acf !== 'undefined') {
      acf.unload.active = false;
    }
  })
})(jQuery)
