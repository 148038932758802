class BlogController {

  constructor () {
    this.$container = $('.tpl-blog')

    if (this.$container.length === 0) return

    this.$filters_form = $('#blog-filters')

    this.$filters_form.on('submit', this.handleSubmitFilters.bind(this))
  }

  handleSubmitFilters (e) {
    e.preventDefault()
    let url = this.$filters_form.find('select option:selected').data('url')

    if (GlobalController.validateUrl(url)) {
      window.location.href = url
    }
  }
}