class SectionSliderOffers {

  constructor () {
    let slider = [{
      slider: '.slider-offer-slider',
      args: {
        slidesToScroll: 3,
        slidesToShow: 3,
        centerMode: true,
        adaptiveHeight: false,
        centerPadding: '165px',
        prevArrow: '<a href="#" class="slick-prev"><i class="icon icon-arrow left"></i></a>',
        nextArrow: '<a href="#" class="slick-next"><i class="icon icon-arrow right"></i></a>',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              centerPadding: '82.5px',
              slidesToScroll: 2,
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 992,
            settings: {
              centerPadding: '41px',
              slidesToScroll: 1,
              slidesToShow: 1,
            }
          }
        ]
      }
    }]
    new SliderController(slider)
  }

}

new SectionSliderOffers()