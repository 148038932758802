class HeaderController {

  constructor () {
    this.$window = $(window)
    this.$body = $('body')
    this.$header = $('.header')
    this.$burger_button = $('#burger-button')
    this.$nav = $('.header-nav')

    this.sticky_class = 'sticky'
    this.open_menu_class = 'open-menu'
    this.sub_menu_class = 'open-menu-sub'
    this.close_sub_menu_class = 'header-nav-sub-item-back'

    this.$burger_button.on('click', this.openMenu.bind(this))
    this.$window.on('scroll', this.stickyNav.bind(this))
    this.$window.on('resize', this.stickyNav.bind(this))
    this.$nav.on('click', 'a:not(.'+this.close_sub_menu_class+')', this.handleClickNavItem.bind(this))
    this.$header.on('click', 'a.'+this.close_sub_menu_class+'', this.handleClickNavItemClose.bind(this))

    this.stickyNav()
  }

  openMenu (e) {
    e.preventDefault()

    this.$header.toggleClass(this.open_menu_class)
    this.$body.toggleClass(this.open_menu_class)

    this.$header.find('.'+this.sub_menu_class).removeClass(this.sub_menu_class)
    // refresh sticky nav
    // console.log(this.$window.scrollTop())
    // this.$window.scrollTop(this.$window.scrollTop() + 1)
    // console.log(this.$window.scrollTop() + 1)
  }

  stickyNav () {
    let windowsTop = this.$window.scrollTop()

    if (windowsTop > 0) {
      this.$header.addClass(this.sticky_class)
    } else {
      this.$header.removeClass(this.sticky_class)
    }
  }

  handleClickNavItem (e) {
    const width = GlobalController.getWindowWidthCategory()

    if (width === 'xs' || width === 'sm' || width === 'md') {
      let $line = $(e.target).closest('li')
      if ($line.find('ul').length) {
        e.preventDefault()
        $line.addClass(this.sub_menu_class)
      }
    }
  }

  handleClickNavItemClose (e) {
    e.preventDefault()
    this.$header.find('.'+this.sub_menu_class).removeClass(this.sub_menu_class)
  }
}
