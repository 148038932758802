class SliderController {

  constructor (sliders) {
    this.cpt_reinit = 0
    this.sliders = sliders
    this.init()

    $(document).ajaxComplete(this.reinit.bind(this))
  }

  init () {
    jQuery.each(this.sliders, function (i, val) {
      let $slider = $(val.slider)

      if ($slider.length) {
        $slider.slick(val.args)
      }
    })
  }

  reinit () {
    let self = this
    self.cpt_reinit++
    let cpt = self.cpt_reinit

    setTimeout(function () {
      if (cpt === self.cpt_reinit) {
        jQuery.each(self.sliders, function (i, val) {
          let $slider = $(val.slider)

          if ($slider.length && !$slider.hasClass('slick-initialized')) {
            $slider.slick(val.args)
          }
        })
      }
    }, 300)
  }
}
