class GlobalController {

  constructor () {
    this.$window = $(window)
    this.$back_top = $('#back-top')

    this.$back_top.on('click', this.handleClickBackTop.bind(this))
    this.$window.on('scroll', this.handleScrollBackTop.bind(this))

    if (window.location.hash) {
      let $element = $(window.location.hash)
      if ($element.length) {
        let scroll = $element.offset().top - $('.header').height()

        $('html, body').stop().animate({
          scrollTop: scroll
        }, 300)
      }
    }
  }

  handleClickBackTop (e) {
    e.preventDefault()
    $('html, body').stop().animate({
      scrollTop: 0
    }, 300)
    return false
  }

  handleScrollBackTop (e) {
    if (this.$window.scrollTop() > 0) {
      this.$back_top.show(300)
    } else {
      this.$back_top.hide(50)
    }
  }

  static getWindowWidthCategory () {
    let window_width = $(window).width(),
      // xs = 0,
      sm = 576,
      md = 768,
      lg = 992,
      xl = 1200,
      xxl = 1440

    if (window_width >= xxl) {
      return 'xxl'
    } else if (window_width >= xl) {
      return 'xl'
    } else if (window_width >= lg) {
      return 'lg'
    } else if (window_width >= md) {
      return 'md'
    } else if (window_width >= sm) {
      return 'sm'
    } else {
      return 'xs'
    }
  }

  static validateUrl (url) {
    let pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i')
    return !!pattern.test(url)
  }

  static isAdmin () {
    return $('body').hasClass('wp-admin')
  }

}
