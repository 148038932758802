class TrackingController
{
    constructor()
    {
        var self = this;
        
        // STORE CONTACT 
        $(document).on('click','.btn',function() {

            if(this.getAttribute('data-dl-store-name')){

                var vars = {
                    'event': 'store-contact',
                    'store-name': $(this).attr("data-dl-store-name"),
                };

                self.tagEvent(vars);

            }
        });

        /* DATA LAYER CLICK */
        var links = document.querySelectorAll('a');
        links.forEach(function(link) {
            if(link.getAttribute('data-dl')){
                var vars={};

                link.addEventListener("click", function(){

                    var dataAttributes =  link.attributes;

                    for (var i = 0; i < dataAttributes.length; i++){

                        var expr = /^(data\-dl\-).+/;
                        var name = dataAttributes[i].nodeName;
                        var value = dataAttributes[i].nodeValue;

                        if(expr.test(name)){
                            if(name == "data-dl-link-url"){
                                if(value){
                                    vars["link-url"] = value;
                                }else {
                                    vars["link-url"] = link.href;
                                }
                            }else if(name == "data-dl-link-text"){
                                if(value){
                                    vars["link-text"] = value;
                                }else {
                                    vars["link-text"] = link.innerText;
                                }

                            }else{
                                var nameClean = name.replace(/data-dl-/g,'');
                                vars[nameClean] = value;
                            }
                        }

                    }

                    self.tagEvent(vars);
                });

            } else {
                if($(link).parents('[data-wysiwyg-dl="true"]').length > 0){
                    var parent = $(link).parents('[data-wysiwyg-dl="true"]');
                    var vars={};

                    link.addEventListener("click", function(){
                        var dataAttributes =  parent[0].attributes;
                        for (var i = 0; i < dataAttributes.length; i++){

                            var expr = /^(data\-dl\-).+/;
                            var name = dataAttributes[i].nodeName;
                            var value = dataAttributes[i].nodeValue;

                            if(expr.test(name)){
                                var nameClean = name.replace(/data-dl-/g,'');
                                vars[nameClean] = value;
                            }
                        }
                        vars["link-text"] = link.innerText;
                        vars["link-url"] = link.href;

                        self.tagEvent(vars);
                    });
                }
            }
        });
        
        /* DATA LAYER SCROLL DISPLAY */
        var ticking = false;
        window.addEventListener('scroll', function (e) {
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    self.initWhenYouCan();
                    ticking = false;
                });
            }
            ticking = true;
        });
        
    }

    tagEvent( vars ) {
        window.dataLayer = dataLayer || [];
        dataLayer.push(vars);
    }

    initWhenYouCan() {
        var self = this,
          elements = document.querySelectorAll('[data-display-dl="false"]');

        elements.forEach(function (element) {

            if ( self.isElementInViewport(element)) {
                var dataAttributes = element.attributes;
                var vars = {};

                for (var i = 0; i < dataAttributes.length; i++) {

                    var expr = /^(data\-display\-dl\-).+/;
                    var name = dataAttributes[i].nodeName;
                    var nameClean = name.replace(/data-display-dl-/g, '');
                    var value = dataAttributes[i].nodeValue;

                    if (expr.test(name)) {
                        vars[nameClean] = value;
                    }
                }

                vars["event"] = "display";
                self.tagEvent(vars);
                element.setAttribute("data-display-dl", "true");
            }

        });
    }

    isAnyPartOfElementInViewport(el) {
        var rect = el.getBoundingClientRect();
    
        // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
        var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        var windowWidth  = (window.innerWidth  || document.documentElement.clientWidth);
    
        // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
        var vertInView = (rect.top  <= windowHeight) && ((rect.top + rect.height) >= 0);
        var horInView  = (rect.left <= windowWidth)  && ((rect.left + rect.width) >= 0);
    
        return (vertInView && horInView);
    }

    isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
    
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document. documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document. documentElement.clientWidth)
        );
    }

}
