class SelectorController {

  constructor () {
    this.selector_class = 'selector'
    this.selector_initialized_class = 'selector-init'
    this.selector_open_class = 'open'
    this.selected_class = 'selected'

    this.init()

    $(document).on('click', this.handleClickDocument.bind(this))
    $('body').on("DOMSubtreeModified", this.init.bind(this))
  }

  init () {
    let $selects
    if (GlobalController.isAdmin()) {
      $selects = $(`select.selector:not(.${this.selector_initialized_class})`)
    } else {
      $selects = $(`select.selector:not(.${this.selector_initialized_class}), .acf-input select:not(.${this.selector_initialized_class})`)
    }
    if ($selects.length) {
      $selects.each(this.initSelect.bind(this))
    }
  }

  initSelect (i, select) {
    let $select = $(select)

    this.constructSelector($select)
  }

  handleClickSelector (e) {
    let $selector = $(e.target)

    if (!$selector.hasClass(`${this.selector_class}`)) {
      $selector = $selector.closest(`.${this.selector_class}`)
    }

    if ($selector.hasClass(this.selector_open_class)) {
      $selector.removeClass(this.selector_open_class)
    } else {
      $selector.addClass(this.selector_open_class)
      $(`.${this.selector_class}`).not($selector).removeClass(this.selector_open_class)
    }
  }

  handleClickSelectorChoice (e) {
    e.preventDefault()
    let $choice = $(e.target)
    let $selector = $choice.closest(`.${this.selector_class}`)
    let $select = $selector.find('select')
    let $current = $selector.find('.current span')

    $selector.find(`.${this.selected_class}`).removeClass(this.selected_class)
    $choice.addClass(this.selected_class)

    $current.text($choice.text())
    $select.val($choice.data('value')).trigger('change')
    $selector.removeClass(this.selector_open_class)
  }

  handleChangeSelect (e) {
    let element = $(e.target)
    let $selector_container = element.closest(`.${this.selector_class}`)
    let $select = $selector_container.find('select')
    let $selector = $select.closest(`.${this.selector_class}`)
    let $current_text = $selector.find('.current span')

    let $option_selected = $select.find('option:selected')
    if ($option_selected.length === 0) {
      $option_selected = $select.find('option:first-child')
    }
    $current_text.text($option_selected.text())
  }

  handleClickDocument (e) {
    let element = $(e.target)
    let $selector_container = element.closest(`.${this.selector_class}`)

    if ($selector_container.length === 0 && !element.hasClass(`.${this.selector_class}`)) {
      $(`.${this.selector_class}`).not($selector_container).removeClass(this.selector_open_class)
    }
  }

  constructSelector ($select) {
    $select.addClass(this.selector_initialized_class)
    $select.wrap('<div class="selector"></div>')

    let $selector = $select.parent()

    let $selected = $select.find('option:selected')
    if ($selected.length === 0) {
      $selected = $select.find('option:first-child')
    }

    let selected_index = $selected.index()
    $selector.append(`<div class="current"><span>${$selected.text()}</span><i class="icon icon-chevron" aria-hidden="true"></i></div>`)
    $selector.append(`<ul class="list"></ul>`)

    let $selector_list = $selector.find('.list')

    $select.find('option').each((i, option) => {
      let $option = $(option)
      $selector_list.append(`<li class="${i === selected_index ? 'selected' : ''}${i === 0 ? ' placeholder' : ''}" data-value="${$option.attr('value')}">${$option.text()}</li>`)
    })

    let $current = $selector.find('.current')
    let $choice = $selector_list.find('li')

    $select.on('change', this.handleChangeSelect.bind(this))
    $current.on('click', this.handleClickSelector.bind(this))
    $choice.on('click', this.handleClickSelectorChoice.bind(this))
  }

  static resetSelector ($selector) {
    let $current_text = $selector.find('.current')
    let $choice_default = $selector.find('.option:first-child')
    let $select_tag = $selector.find('select')

    $select_tag.val($choice_default.data('value')).trigger('change')
    $current_text.text($choice_default.text())
  }
}
