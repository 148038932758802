class SectionSteps {

  constructor () {
    /* GSAP */
    this.container_class = '.section-steps'
    this.row_class = '.section-steps-row'
    this.$container = $(this.container_class)

    if (this.$container.find(this.row_class).length > 0) {
      this.initGsap()

      this.$main = $('main')
      this.$cta = this.$container.find(this.container_class+'-cta')

      if (this.$main.find('form')) {
        this.$cta.on('click', this.handleClickCta.bind(this))
      } else {
        this.$cta.hide()
      }
    }
  }

  handleClickCta (e) {
    e.preventDefault()
    let scroll = this.$main.find('form').closest('section').offset().top - $('.header').height()

    $('html, body').stop().animate({
      scrollTop: scroll
    }, 300)
  }

  initGsap () {
    /* GSAP */
    let self = this
    // let container = document.querySelector(this.container_class)

    gsap.registerPlugin(ScrollTrigger)

    gsap.utils.toArray(this.row_class).forEach((section, i) => {
      let elements = section.querySelectorAll('[data-depth-from]')

      if (elements.length) {
        const timeline = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            end: 'bottom top',
            scroller: self.containerClass,
            scrub: true,
            invalidateOnRefresh: true
          }
        })
        elements.forEach((elt, i) => {
          let height = innerHeight
          let depthFrom = elt.dataset.depthFrom
          let depthTo = elt.dataset.depthTo

          const movementFrom = height * depthFrom
          const movementTo = height * depthTo

          timeline.fromTo(elt, {
            y: movementFrom,
            ease: 'none'
          }, {
            y: movementTo,
            ease: 'none'
          })

          // let $elt = $(elt) //.find(self.menu_selector)
          // ScrollTrigger.create({
          //   trigger: elt,//self.menu_selector,
          //   start: 'center -=150',
          //   end: 'center +=150',
          //   markers: true,
          //   pin: true,
          // })
        })
      }
    })
  }
}

new SectionSteps()