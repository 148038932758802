class HeroController {

  constructor () {
    $('.scroll-down-link').on('click', this.handleClickScrollDownLink.bind(this))
  }

  handleClickScrollDownLink (e) {
    e.preventDefault()
    let scroll = $(window).height() - $('.header').height()
    $("html, body").stop().animate({scrollTop: scroll}, 300);
  }
}
